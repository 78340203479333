<template>
  <div class="QrForm">
    <div class="holder shadow-lg rounded-md">
      <div class="input px-3">
        <label for="QR-Value">QR-Value</label>
        <br />
        <br />
        <input type="text" name="QR-Value" v-model="value" class="rounded px-3" style="border:1px solid gray; width:100%;"/>
      </div>
      <qrcode-vue :value="value" :size="size" level="H" id="qr" />
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  data() {
    return {
      value: "sdfsd",
      size: 200,
    };
  },
  components: {
    QrcodeVue,
  },
};
</script>